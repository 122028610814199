import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import _get from 'lodash/get';

import Layout from '@components/Layout';
import VacancyComponent from '@components/Vacancy';
import TestimonialSlider from '@components/TestimonialSlider';

import { extractGraphqlData } from '@utils/extractors';
import { linkResolver } from '@utils/Prismic/linkResolver';
import { TGatsbyImage, TPrismicLink, TPrismicSEO } from '@utils/types';

export type TTestimonial = {
  node: {
    name: string[];
    position: string[];
    started_in: string;
    testimonial_body: string[];
    photo: {
      url: string;
    };
    photoSharp: TGatsbyImage;
    qualifications: {
      description: string[];
    }[];
  };
};

type TCareers = {
  node: {
    heading: string[];
    description: string[];
    link_text: string[];
    seo: TPrismicSEO;
  };
};

export type TVacancies = {
  node: {
    position_title: string[];
    short_description: string[];
    link: TPrismicLink;
    link_text: string;
    priority: number;
  };
};
interface ICareersProps {
  data: {
    prismic: {
      careers: {
        edges: TCareers[];
      };
      testimonials: {
        edges: TTestimonial[];
      };
      vacancies: {
        edges: TVacancies[];
      };
    };
  };
}

const Careers: React.FC<ICareersProps> = ({ data }) => {
  const careersDoc = extractGraphqlData(data, 'careers');
  const testimonialsDoc = extractGraphqlData(data, 'testimonials');
  const vacanciesDoc = extractGraphqlData(data, 'vacancies');

  if (!careersDoc || !testimonialsDoc || !vacanciesDoc) return null;

  const sharpImage = _get(testimonialsDoc, ['node', 'photoSharp', 'childImageSharp', 'fluid']);
  const vacancies = _get(data, ['prismic', 'vacancies', 'edges']);
  const testimonials = _get(data, ['prismic', 'testimonials', 'edges']);

  const scrollTo = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (typeof window !== 'undefined') {
      event.preventDefault();

      const get_top = () => {
        return document.querySelector('.vacancies')!.getBoundingClientRect().top;
      };

      const ANIMATION_TIME = 500; // ms
      const ANIMATION_STEP = 15; // ms
      const MAX_LOOP = (ANIMATION_TIME / ANIMATION_STEP) * 1.5;

      const scrollStep = get_top() / (ANIMATION_TIME / ANIMATION_STEP);
      let counter = 0;

      let scrollInterval = setInterval(() => {
        
        if (get_top() > 0 && counter < MAX_LOOP) {
          window.scrollBy(0, scrollStep);
        } else {
          clearInterval(scrollInterval);
        }

        counter += 1;
      }, ANIMATION_STEP);
    }
  };
  const { page_title, page_description, canonical_tag, page_og_image } = careersDoc.node.seo[0];

  return (
    <Layout
      title={page_title ? RichText.asText(page_title) : 'Careers'}
      description={page_description && RichText.asText(page_description)}
      firstLevelPage
      norobots      
      seo={{
        path: '/careers',
        canonical: canonical_tag && canonical_tag.url,
        image: page_og_image ? page_og_image : null,        
      }}
    >
      <div className="careers">
        <div className="careers__content-help">
          <div className="careers__content">
            <div className="anim-title">
              <h1>{RichText.asText(careersDoc.node.heading)}</h1>
            </div>
            <div className="careers__description anim-block">
              {RichText.render(careersDoc.node.description, linkResolver)}
            </div>
          </div>
        </div>

        <div className="careers__testimonials">
          <div className="anim-next-block">
            <TestimonialSlider testimonial={testimonials} image={sharpImage} />
          </div>
        </div>
        <div className="careers__vacancies">
          <a href="#vacancies" className="careers__vacancies-link anim-bottom-block" onClick={scrollTo}>
            Our Vacancies
          </a>
          <VacancyComponent data={vacancies} />
        </div>
        <div className="careers__helper"></div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query Careers {
    prismic {
      careers: allCareers_pages {
        edges {
          node {
            heading
            description
            link_text
            seo {
              page_title
              page_description
              page_og_image
              canonical_tag {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
          }
        }
      }
      testimonials: allTestimonials {
        edges {
          node {
            name
            position
            started_in
            testimonial_body
            photo
            photoSharp {
              childImageSharp {
                fluid(maxWidth: 120, maxHeight: 120) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            qualifications {
              description
            }
          }
        }
      }
      vacancies: allVacancys(sortBy: priority_ASC) {
        edges {
          node {
            position_title
            short_description
            link {
              ... on PRISMIC_Vacancy {
                _meta {
                  uid
                  type
                }
              }
            }
            link_text
            priority
            _meta {
              type
              uid
            }
          }
        }
      }
    }
  }
`;
export default Careers;

import React from 'react';
import Img, { FluidObject } from 'gatsby-image';
import { RichText } from 'prismic-reactjs';
import _get from 'lodash/get';

import { linkResolver } from '@utils/Prismic/linkResolver';
import { TTestimonial } from '@pages/careers';

interface ISlideProps {
  data: TTestimonial;
  id: string;
  isCurrent: boolean;
  image: FluidObject;
}

const Slide: React.FC<ISlideProps> = ({ data, id, isCurrent, image }) => {
  return (
    <li aria-hidden={!isCurrent} tabIndex={-1} aria-labelledby={id} className="testimonial__slide">
        <div className="testimonials__testimonial-header">
            <div className="testimonials__testimonial-image">
                {image ? (
                    <Img className="testimonials__testimonial-img" fluid={image} alt="Photo" />
                ) : (
                    <img src={_get(data, ['node', 'image', 'url'])} alt="Photo" />
                )}
            </div>
            <div className="testimonials__testimonial-position">
                <h3>{RichText.asText(data.node.name)}</h3>
                <p>{RichText.asText(data.node.position)}</p>
                <p>Started in {data.node.started_in}</p>
            </div>
        </div>
        <div className="testimonials__testimonial-desc">
            {RichText.render(data.node.testimonial_body, linkResolver)}
        </div>
        <div className="testimonials__testimonial-quals">
            <h3>Qualifications earned</h3>
            <ul>
                {data.node.qualifications.map((qualification, index) => (
                    <li key={index}>{RichText.asText(qualification.description)}</li>
                ))}
            </ul>
        </div>
    </li>
  );
};

export default Slide;

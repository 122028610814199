import { useReducer } from 'react';

import { TTestimonial } from '@pages/careers';

export type TActionType = {
  type: 'NEXT' | 'PREV' | 'GOTO';

  index?: number;
};

const useTestimonialSlider = (testimonial: TTestimonial[]) => {
  let [state, dispatch] = useReducer(
    (state: any, action: TActionType) => {
      switch (action.type) {
        case 'NEXT':
          return {
            ...state,
            currentIndex: (state.currentIndex + 1) % testimonial.length,
          };
        case 'PREV':
          return {
            ...state,
            currentIndex: (state.currentIndex - 1 + testimonial.length) % testimonial.length,
          };
        case 'GOTO': {
          return {
            ...state,
            currentIndex: action.index,
          };
        }

        default:
          return state;
      }
    },
    {
      currentIndex: 0,
    }
  );

  return { state, dispatch };
};

export default useTestimonialSlider;

import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { getLinkOrDefault } from '@utils/Prismic/getLinkOrDefault';
import { linkResolver } from '@utils/Prismic/linkResolver';
import { TVacancies } from '@pages/careers';
import { DEFAULT_READ_MORE } from '@utils/constants';

interface IVacancyComponentProps {
  data: TVacancies[];
}

const VacancyComponent: React.FC<IVacancyComponentProps> = ({ data }) => {
  return (
    <div className="vacancies">
      {data.map(({ node: { position_title, short_description, link, link_text, _meta } }, index) => (
        <Fragment key={index}>
          <div className="vacancies__vacancy anim-block">
            <Link to={linkResolver(getLinkOrDefault(link, _meta))}>
              <h2>{RichText.asText(position_title)}</h2>
            </Link>
            {RichText.render(short_description, linkResolver)}
            <Link className="vacancies__vacancy-link" to={linkResolver(getLinkOrDefault(link, _meta))}>
              <span className="vacancies__vacancy-link-arrow"></span>
              {link_text ? RichText.asText(link_text) : DEFAULT_READ_MORE}
            </Link>
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default VacancyComponent;

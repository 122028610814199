import React from 'react';

interface ISlidesProps {
  index: number;
}

const Slides: React.FC<ISlidesProps> = ({ index, ...props }) => {
  return (
    <div className="testimonial__slides__list">
      <ul {...props} style={{ left: `${-100 * index}%` }} className="testimonial__slides" />
    </div>
  );
};

export default Slides;

import React from 'react';

interface IIconButtonProps {
  [key: string]: any;
}

const IconButton: React.FC<IIconButtonProps> = ({ ...props }) => {
  return <button {...props} />;
};

export default IconButton;

import React from 'react';
import { RichText } from 'prismic-reactjs';
import classNames from 'classnames';

import Carousel from './Carousel';
import Slides from './Slides';
import Slide from './Slide';
import Controls from './Controls';
import IconButton from './IconButton';

import useTestimonialSlider from '@hooks/useTestimonalSlider';
import { TTestimonial } from '@pages/careers';
import { FluidObject } from 'gatsby-image';

interface ITestimonialSlider {
  testimonial: TTestimonial[];
  image: FluidObject;
}

const TestimonialSlider: React.FC<ITestimonialSlider> = ({ testimonial, image }) => {
  const { state, dispatch } = useTestimonialSlider(testimonial);

  return (
    <>
      <Carousel aria-label="Testimonials">
        <Slides index={state.currentIndex}>
          {testimonial.map((t: TTestimonial, index) => (
            <Slide
              key={index}
              id={`testimonial-${index}`}
              data={t}
              image={image}
              isCurrent={index === state.currentIndex}
            />
          ))}
        </Slides>

        <Controls>
          <IconButton
            aria-label="Previous Slide"
            className={
              state.currentIndex === 0
                ? 'testimonials__button testimonials__button--prev disabled'
                : 'testimonials__button testimonials__button--prev'
            }
            onClick={() => {
              if (state.currentIndex !== 0) {
                dispatch({ type: 'PREV' });
              }
            }}
          >
            Prev
          </IconButton>

          <div className="testimonials__navigation-dots">
            {testimonial.map((t, index: number) => (
              <div
                className={classNames('testimonials__navigation-dot', {
                  active: state.currentIndex === index,
                })}
                key={index}
                onClick={() => {
                  dispatch({ type: 'GOTO', index });
                }}
              >
                <span className="testimonials__navigation-title">
                  {RichText.asText(t.node.name)}
                </span>
              </div>
            ))}
          </div>

          <IconButton
            aria-label="Next Slide"
            className={
              state.currentIndex === testimonial.length - 1
                ? 'testimonials__button testimonials__button--next disabled'
                : 'testimonials__button testimonials__button--next'
            }
            onClick={() => {
              if (state.currentIndex < testimonial.length - 1) {
                dispatch({ type: 'NEXT' });
              }
            }}
          >
            Next
          </IconButton>
        </Controls>
      </Carousel>
    </>
  );
};

export default TestimonialSlider;
